/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { ReplenishTaskState, ReplenishTask } from 'commons/types';
import { compareReplenishTasks } from 'commons/utils/compareReplenishTask';
import { ReducerKeys } from 'redux-stores/enums/reducerKey.enum';

const initialState: ReplenishTaskState = {
  replenish_tasks: {},
  replenish_sku_list: [],
  assignedTasks: [],
};

const replenishTaskSlice = createSlice({
  name: ReducerKeys.ReplenishTask,
  initialState,
  reducers: {
    setAssignedReplenishTask(
      state: ReplenishTaskState,
      action: { type: string; payload: ReplenishTask[] },
    ) {
      state.assignedTasks = action.payload;
    },
    addAssignedReplenishTask(
      state: ReplenishTaskState,
      action: { type: string; payload: ReplenishTask },
    ) {
      const { payload: newTask } = action;

      const indexToPush = state.assignedTasks.findIndex(
        (task) => compareReplenishTasks(newTask, task) < 0,
      );

      if (indexToPush === -1) {
        state.assignedTasks.push(newTask);
      } else {
        state.assignedTasks.splice(indexToPush, 0, newTask);
      }
    },
    removeAssignedReplenishTask(
      state: ReplenishTaskState,
      action: { type: string; payload: ReplenishTask },
    ) {
      const updatedTasks = state.assignedTasks.filter(
        (task) => task.sku_code !== action.payload.sku_code,
      );
      state.assignedTasks = updatedTasks;
    },
    updateAssignedReplenishTask(
      state: ReplenishTaskState,
      action: { type: string; payload: ReplenishTask },
    ) {
      const updatedTasks = state.assignedTasks.map((task) =>
        task.sku_code === action.payload.sku_code ? action.payload : task,
      );
      state.assignedTasks = updatedTasks;
    },

    prepareReplenishTasks(
      state: ReplenishTaskState,
      action: { type: string; payload: ReplenishTask[] | null | undefined },
    ) {
      const { payload } = action;
      if (payload && payload.length > 0) {
        state.assignedTasks = payload;
      }
      const { assignedTasks } = state;
      const replenishTask: Record<string, ReplenishTask> = {};
      const skuList: string[] = [];
      assignedTasks.forEach((task) => {
        replenishTask[task.sku_code] = task;
        skuList.push(task.sku_code);
      });
      state.replenish_tasks = replenishTask;
      state.replenish_sku_list = skuList;
    },

    updateReplenishTaskBySkuCode(
      state: ReplenishTaskState,
      action: { type: string; payload: ReplenishTask },
    ) {
      const { payload } = action;
      state.replenish_tasks[payload.sku_code] = payload;
      if (state.replenish_sku_list.length === 0) {
        state.replenish_sku_list = [payload.sku_code];
      }
    },
  },
});

export const {
  setAssignedReplenishTask,
  addAssignedReplenishTask,
  updateAssignedReplenishTask,
  removeAssignedReplenishTask,
  prepareReplenishTasks,
  updateReplenishTaskBySkuCode,
} = replenishTaskSlice.actions;

export const replenishTaskReducer = replenishTaskSlice.reducer;

export const selectAssignedTasks = (state: any) => state.assignedTasks;
