import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { capitalize, Divider, Grid, Typography } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import {
  CriteriaOption,
  SearchWithCriteria,
} from 'commons/components/SearchRelated';
import { useUpdateQueryParams } from 'commons/components/SearchRelated/SearchWithCriteria/hooks';
import {
  StockTransferSearchCriteriaEnum,
  StockTransferStatusEnum,
} from 'commons/enums';
import { getStockTransferListAction } from 'redux-stores/actions';
import { RootReducerInterface } from 'redux-stores/reducers';

import { ListFilterCard, ListPage } from '../commons/components';

import StockTransferTable from './components/StockTransferTable.component';

const StockTransferListPage: React.FC = () => {
  const { queryParams, updateQueryParams } = useUpdateQueryParams();

  const { stockTransferList } = useSelector(
    (state: RootReducerInterface) => state.dashboard,
  );

  const updateStockTransferListSearchDebounce = useDebouncedCallback(
    (_searchParams, _location, _dispatch) => {
      _dispatch(
        getStockTransferListAction({
          searchParams: _searchParams,
          locationId: _location,
        }),
      );
    },
    200,
    { maxWait: 1000 },
  );

  const searchCriteria: CriteriaOption[] = [
    {
      name: 'SKU',
      id: 'sku_code',
      isArray: true,
      parameterMapping: 'sku_codes',
    },
    {
      name: 'User',
      id: 'user',
    },
  ];

  const stockTranferStatusArrayText: {
    key: StockTransferSearchCriteriaEnum;
    text: string;
  }[] = [
    ...Object.values(StockTransferSearchCriteriaEnum).map((value) => ({
      key: value,
      text: capitalize(value.replace('-', ' ')),
    })),
  ];

  const [statusFilter, setStatusFilter] =
    useState<StockTransferSearchCriteriaEnum | null>(null);

  const handleStatusFilter = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: StockTransferSearchCriteriaEnum,
  ): void => {
    setStatusFilter(value);
    updateQueryParams({ status: value }, { clearQueryParams: ['page'] });
  };

  return (
    <ListPage updateListSearchDebounce={updateStockTransferListSearchDebounce}>
      <ListFilterCard
        statusArrayText={stockTranferStatusArrayText}
        statusFilter={statusFilter}
        handleStatusFilter={handleStatusFilter}
        searchCriteria={searchCriteria}
      />
      {Object.keys(queryParams).length > 0 ? (
        <StockTransferTable
          data={stockTransferList}
          statusFilter={statusFilter}
        />
      ) : (
        <Grid container justifyContent="center">
          <Typography>Please select a status</Typography>
        </Grid>
      )}
    </ListPage>
  );
};

export default StockTransferListPage;
