import React from 'react';

import { Grid } from '@mui/material';

import { TableComponent } from 'commons/components/Table';
import { IStockTransferItemProps } from 'commons/types';

import { StockTransferSummaryStyle as S } from '../StockTransferSummary.style';

import { remainsTableConfig, summaryTableConfig } from './configs';

interface IStockTransferBinListProps {
  sources: IStockTransferItemProps[];
  destinations: IStockTransferItemProps[];
  remainsQty?: { inventory_number: string; quantity: number }[];
}

export const StockTransferBinList: React.FC<IStockTransferBinListProps> = ({
  sources,
  destinations,
  remainsQty,
}) => {
  return (
    <>
      <S.ItemContentWrapper container>
        <Grid item xs={12}>
          <S.SubHeaderText>Source</S.SubHeaderText>
        </Grid>
        <TableComponent
          data={sources || []}
          config={summaryTableConfig}
          additionalTableConfig={{ bodyFontSize: '12px' }}
        />
      </S.ItemContentWrapper>

      <S.ItemContentWrapper container>
        <Grid item xs={12}>
          <S.SubHeaderText>Destination</S.SubHeaderText>
        </Grid>
        <TableComponent
          data={destinations || []}
          config={summaryTableConfig}
          additionalTableConfig={{ bodyFontSize: '12px' }}
        />
      </S.ItemContentWrapper>

      {remainsQty && remainsQty.length > 0 && (
        <S.ItemContentWrapper container>
          <Grid item xs={12}>
            <S.SubHeaderText>Unplaced Items</S.SubHeaderText>
          </Grid>
          <TableComponent
            data={remainsQty || []}
            config={remainsTableConfig}
            additionalTableConfig={{ bodyFontSize: '12px' }}
          />
        </S.ItemContentWrapper>
      )}
    </>
  );
};
