import { Button, Grid } from '@mui/material';
import styled from 'styled-components';

import {
  PRIMARY_BLUE,
  LIGHTER_BLUE,
  LIGHTER_GRAY,
  PRIMARY_GRAY,
} from 'commons/styles/colors';

class UploaderStyle {
  UploaderContainer = styled(Grid).attrs(() => ({
    container: true,
    direction: 'row',
    justify: 'center',
    alignItems: 'center',
  }))`
    && {
      position: relative;
      width: 100%;
      border-radius: 4px;
      border: 1px dashed ${LIGHTER_BLUE};
      padding: 2rem;
      background: ${LIGHTER_GRAY};
    }
  `;

  ActionContainer = styled(Grid).attrs(() => ({
    container: true,
    item: true,
    sm: 12,
    direction: 'column',
    alignContent: 'center',
    alignItems: 'center',
  }))`
    padding-top: 24px;
  `;

  IconContainer = styled.div`
    text-align: center;
    padding-bottom: 16px;
  `;

  ChooseFile = styled(Button)`
    && {
      background: white;
      text-transform: none;
      border-color: ${PRIMARY_BLUE};
      :hover {
        border-color: ${LIGHTER_BLUE};
      }
    }
  `;

  ChosenFileDescription = styled(Button)`
    && {
      background: white;
      text-transform: none;
      color: ${PRIMARY_GRAY};
      justify-content: left;
      min-width: 320px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-color: ${PRIMARY_BLUE};
      :hover {
        border-color: ${LIGHTER_BLUE};
      }
    }
  `;

  Upload = styled(Button).attrs(() => ({
    variant: 'contained',
  }))`
    && {
      margin-left: 16px;
      border-radius: 4px;
      text-transform: none;
      min-width: 100px;
    }
  `;
}

export const S = new UploaderStyle();
