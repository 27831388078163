/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import React, { useState, useMemo } from 'react';

import { IInvoiceMappedData } from '../../interfaces';
import { InvoicePrintTemplateComponent } from '../invoice-template';
import { TTFPrintTemplateComponent } from '../ttf-template';

const InvoiceTTFPrintTemplate: React.FC = () => {
  document.body.className = 'print-template';
  const data: IInvoiceMappedData = useMemo(
    () => JSON.parse(localStorage.getItem('invoice-ttf') || ''),
    [],
  );
  const [ttfPageBreak, setTtfPageBreak] = useState<React.JSX.Element[]>([]);

  const setInvoiceMaxPageNumber = (pageNumber: number) => {
    // set ttf page break
    const pageBreak: React.JSX.Element[] = [];
    for (let i = 0; i < pageNumber - 1; i++) {
      pageBreak.push(<div style={{ pageBreakAfter: 'always' }} />);
    }
    setTtfPageBreak(pageBreak);
  };

  return (
    <>
      <InvoicePrintTemplateComponent
        data={data}
        setMaxPageNumber={setInvoiceMaxPageNumber}
      />
      {ttfPageBreak}
      <InvoicePrintTemplateComponent
        data={data}
        setMaxPageNumber={setInvoiceMaxPageNumber}
        copyNumber={1}
      />
      {ttfPageBreak}
      <TTFPrintTemplateComponent data={data} />
    </>
  );
};

export default InvoiceTTFPrintTemplate;
