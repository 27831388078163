import {
  Grid,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';

class InvoicePrintTemplateStyle extends CommonComponentStyle {
  PageWrapper = styled.div`
    padding: 0 8mm 16mm 8mm;
    width: 210mm;
    height: 297mm;
    @media print {
      size: portrait;

      .pagebreak-after {
        page-break-after: always !important;
      }

      .print-barcode {
        display: block;
        page-break-inside: avoid;
      }
    }
  `;

  PageHeader = styled(TableHead)`
    width: 210mm;
    margin-top: 8mm !important;
  `;

  PageBody = styled(TableBody)`
    margin-top: 16px !important;
    page-break-inside: auto;
  `;

  PageFooter = styled(TableFooter)<{ pageOffset?: number }>`
    position: absolute;
    width: 210mm;
    height: 30mm;
    bottom: 0;

    &.last-page {
      margin-bottom: -${(props) => (props.pageOffset ? props.pageOffset * 100 : 0)}vh;
    }
  `;

  PageNumberText = styled.p`
    font-size: 12px;
    margin-top: 8mm;
  `;

  DigitalSignatureLabel = styled.div`
    color: red;
    border: 1px solid red;
    width: 50%;
    padding: 8px 0;
    margin-left: 8px;
  `;

  FakturLabel = styled.div`
    font-weight: 700;
    text-align: right;
    font-size: 18px;
  `;

  HeaderCopeWrapper = styled(Grid)`
    margin-top: 8mm !important;
  `;

  InvoiceCopeWrapper = styled(Grid)`
    border: 1px solid black;
    font-size: 14px;
    margin-top: 8px;
  `;

  CompanyProfileWrapper = styled(Grid)`
    padding: 4px 8px 4px 24px;
  `;

  CompanyAddressText = styled.div`
    white-space: pre-line;
    line-height: 105%;
    margin: 2px 0;
  `;

  OrderTypeText = styled.div`
    font-weight: 700;
    font-size: 18px;
    margin: 4px 0;
  `;

  CustomerInfoWrapper = styled(Grid)`
    border: 1px solid black;
    font-size: 12px;
    margin-top: 8px;
    height: 60px;
    margin-bottom: 8px !important;
  `;

  CustomerInfoContent = styled(Grid)`
    margin: 2px 0 !important;
    padding-left: 8px;
    white-space: pre-line;
  `;

  TableHeadStyle = styled(TableHead)`
    border: 1px solid black;
  `;

  TableHeaderCell = styled(TableCell)`
    font-weight: 700 !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
  `;

  TableBodyStyle = styled(TableBody)`
    border: 1px solid black;
  `;

  TableRowStyle = styled(TableRow)`
    height: 12mm !important;
  `;

  TableBodyCell = styled(TableCell)`
    line-height: 100% !important;
    padding: 4px !important;
    vertical-align: top !important;
  `;

  SummarySectionWrapper = styled(Grid)`
    font-size: 14px;
    margin-top: 16px;
  `;

  SignatureSectionWrapper = styled(Grid)`
    margin-top: 24px !important;
  `;

  HandOverSectionWrapper = styled(Grid)`
    margin-top: 48px !important;
  `;
}

export default new InvoicePrintTemplateStyle();
