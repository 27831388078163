import React from 'react';

import { Button, Drawer } from '@mui/material';

import { PRIMARY_WHITE } from 'commons/styles/colors';

import { ImagePreviewStyle as S } from './ImagePreview.styles';

interface ImagePreviewProps {
  openModal: boolean;
  onClose: () => void;
  imageSrc: string;
}

export const ImagePreview: React.FC<ImagePreviewProps> = ({
  openModal,
  onClose,
  imageSrc,
}) => {
  return (
    <Drawer
      style={{ position: 'relative', width: '100%', height: '100%' }}
      anchor="bottom"
      open={openModal}
      onClose={onClose}
    >
      <img src={imageSrc} alt="basket check" />
      <S.CameraHeader container>
        <Button
          style={{ cursor: 'pointer', color: PRIMARY_WHITE, fontSize: '16px' }}
          onClick={onClose}
        >
          Close
        </Button>
      </S.CameraHeader>
    </Drawer>
  );
};
