import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  HealthAndSafety,
  Medication,
  Science,
  Groups,
} from '@mui/icons-material';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Stack,
  colors,
  Select,
  MenuItem,
  capitalize,
  SelectChangeEvent,
} from '@mui/material';
import { LineChart } from '@mui/x-charts';

import { Header } from 'commons/components/Header';
import { Sidebar } from 'commons/components/Sidebar/Sidebar.component';
import { DashboardType } from 'commons/enums';
import {
  getActiveOrderSummaries,
  getShiftSummaries,
} from 'redux-stores/actions';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import { BaseDashboardStyle as S } from '../commons/styles';

const SummariesPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { summaries, selectedLocation } = useSelector(
    (state: RootReducerInterface) => state.dashboard,
  );
  const [dashboardType, setDashboardType] = useState(DashboardType.PICKING);

  useEffect(() => {
    dispatch(
      getActiveOrderSummaries({
        location: selectedLocation?.warehouse_id,
        type: dashboardType,
      }),
    );
    dispatch(
      getShiftSummaries({
        location: selectedLocation?.warehouse_id,
        type: dashboardType,
      }),
    );
  }, [dashboardType, dispatch, selectedLocation]);

  const { activeOrderSummaries, shiftSummaries } = summaries;
  const { orderCounts, currentActiveUserCount } = activeOrderSummaries;
  const { orderPerShifts, topUsers } = shiftSummaries;

  const handleSelectDashboard = (e: SelectChangeEvent<DashboardType>): void => {
    setDashboardType(e.target.value as DashboardType);
  };

  return (
    <S.FlexboxWrapper>
      <Header
        title="Dashboard"
        prevPageHandler={() => {
          navigate('/');
        }}
      />
      <S.DashboardWrapper>
        <Sidebar currentUrl={window?.location?.pathname} />
        <S.DashboardContentWrapper>
          <S.DashboardContentContainer>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select
                  onChange={handleSelectDashboard}
                  value={dashboardType}
                  placeholder="Dashboard Type"
                  style={{ width: 200, marginTop: 8 }}
                >
                  {Object.values(DashboardType).map((value) => (
                    <MenuItem value={value}>{capitalize(value)}</MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Card
                  variant="outlined"
                  style={{ height: '12em', alignContent: 'center' }}
                >
                  <CardContent>
                    <S.DashboardCardContentHeader>
                      <S.DashboardCardIcon color={colors.red[600]}>
                        <HealthAndSafety
                          style={{
                            width: '1.5rem',
                          }}
                        />
                      </S.DashboardCardIcon>
                      <Stack spacing={0}>
                        <Typography variant="h5" component="h2">
                          {orderCounts.regular.in_queue}
                          <Typography variant="subtitle1" component="h2">
                            Queue
                          </Typography>
                        </Typography>
                        <Typography variant="h6" component="h2">
                          {orderCounts.regular.in_progress}
                          <Typography variant="subtitle2" component="h2">
                            In progress
                          </Typography>
                        </Typography>
                        <Typography
                          variant="body2"
                          component="p"
                          style={{ fontWeight: 'bold' }}
                        >
                          Regular Orders
                        </Typography>
                      </Stack>
                    </S.DashboardCardContentHeader>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <Card
                  variant="outlined"
                  style={{ height: '12em', alignContent: 'center' }}
                >
                  <CardContent>
                    <S.DashboardCardContentHeader>
                      <S.DashboardCardIcon color={colors.blue[600]}>
                        <Medication
                          style={{
                            width: '1.5rem',
                          }}
                        />
                      </S.DashboardCardIcon>
                      <Stack spacing={0}>
                        <Typography variant="h5" component="h2">
                          {orderCounts.alkes.in_queue}
                          <Typography variant="subtitle1" component="h2">
                            Queue
                          </Typography>
                        </Typography>
                        <Typography variant="h6" component="h2">
                          {orderCounts.alkes.in_progress}
                          <Typography variant="subtitle2" component="h2">
                            In progress
                          </Typography>
                        </Typography>
                        <Typography
                          variant="body2"
                          component="p"
                          style={{ fontWeight: 'bold' }}
                        >
                          Alkes Orders
                        </Typography>
                      </Stack>
                    </S.DashboardCardContentHeader>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <Card
                  variant="outlined"
                  style={{ height: '12em', alignContent: 'center' }}
                >
                  <CardContent>
                    <S.DashboardCardContentHeader>
                      <S.DashboardCardIcon color={colors.green[600]}>
                        <Science
                          style={{
                            width: '1.5rem',
                          }}
                        />
                      </S.DashboardCardIcon>
                      <Stack spacing={0}>
                        <Typography variant="h5" component="h2">
                          {orderCounts.precursor.in_queue}
                          <Typography variant="subtitle1" component="h2">
                            Queue
                          </Typography>
                        </Typography>
                        <Typography variant="h6" component="h2">
                          {orderCounts.precursor.in_progress}
                          <Typography variant="subtitle2" component="h2">
                            In progress
                          </Typography>
                        </Typography>
                        <Typography
                          variant="body2"
                          component="p"
                          style={{ fontWeight: 'bold' }}
                        >
                          Precursor Orders
                        </Typography>
                      </Stack>
                    </S.DashboardCardContentHeader>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <Card
                  variant="outlined"
                  style={{ height: '12em', alignContent: 'center' }}
                >
                  <CardContent>
                    <S.DashboardCardContentHeader>
                      <S.DashboardCardIcon color={colors.yellow[800]}>
                        <Groups
                          style={{
                            width: '1.5rem',
                          }}
                        />
                      </S.DashboardCardIcon>
                      <Stack spacing={0}>
                        <Typography variant="h5" component="h2">
                          {currentActiveUserCount}
                        </Typography>
                        <Typography
                          variant="body2"
                          component="p"
                          style={{ fontWeight: 'bold' }}
                        >
                          Active {capitalize(dashboardType)} Users
                        </Typography>
                      </Stack>
                    </S.DashboardCardContentHeader>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Card variant="outlined">
                  <CardContent>
                    <S.DashboardCardContentHeader>
                      <Typography
                        variant="h5"
                        style={{
                          width: '100%',
                          textAlign: 'center',
                        }}
                        component="h2"
                      >
                        Completed {capitalize(dashboardType)} Order per Day
                      </Typography>
                    </S.DashboardCardContentHeader>
                    <LineChart
                      series={[
                        {
                          label: 'Regular',
                          data: orderPerShifts.map(
                            (orderCount) => orderCount.order_count.regular,
                          ),
                        },
                        {
                          label: 'Alkes',
                          data: orderPerShifts.map(
                            (orderCount) => orderCount.order_count.alkes,
                          ),
                        },
                        {
                          label: 'Precursor',
                          data: orderPerShifts.map(
                            (orderCount) => orderCount.order_count.precursor,
                          ),
                        },
                      ]}
                      xAxis={[
                        {
                          scaleType: 'point',
                          data: orderPerShifts.map(
                            (orderCount) => orderCount.shift,
                          ),
                        },
                      ]}
                      height={400}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={2}>
                  {topUsers.map((user, index) => (
                    <Grid item xs={12}>
                      <Card variant="outlined">
                        <CardContent>
                          <S.DashboardCardContentHeader>
                            <Stack spacing={0}>
                              <Typography variant="h5" component="h2">
                                {user.user}
                              </Typography>
                              <Typography variant="h5" component="h2">
                                Top {capitalize(dashboardType)} User #
                                {index + 1}
                              </Typography>
                              {dashboardType === DashboardType.PICKING ? (
                                <Typography variant="body2" component="p">
                                  Sorted by SKU picked for the last days
                                  <br />
                                  Picked: {user.count} SKUs
                                </Typography>
                              ) : (
                                <Typography variant="body2" component="p">
                                  Sorted by order packed for the last days
                                  <br />
                                  Packed: {user.count} Orders
                                </Typography>
                              )}
                            </Stack>
                          </S.DashboardCardContentHeader>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </S.DashboardContentContainer>
        </S.DashboardContentWrapper>
      </S.DashboardWrapper>
    </S.FlexboxWrapper>
  );
};

export default SummariesPage;
