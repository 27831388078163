import React from 'react';
import { Route } from 'react-router-dom';

import { UserRolesEnum } from 'commons/types/user.roles.enum';
import PrivateRoute from 'commons/utils/privateRoute';
import {
  StockTransferListDestinationPage,
  StockTransferListSourcePage,
} from 'pages/stock-transfer/stock-transfer-list';
import { StockTransferSummaryPage } from 'pages/stock-transfer/stock-transfer-summary';
import {
  StockTransferTaskDestinationPage,
  StockTransferTaskSourcePage,
} from 'pages/stock-transfer/stock-transfer-task';

export const useStockTransferRouter = (): React.JSX.Element => (
  <Route
    path="/stock-transfer"
    element={
      <PrivateRoute
        roles={[
          UserRolesEnum.ADMIN,
          UserRolesEnum.SUPER_ADMIN,
          UserRolesEnum.REPLENISH,
        ]}
      />
    }
  >
    <Route
      path="/stock-transfer/list/source"
      element={<StockTransferListSourcePage />}
    />
    <Route
      path="/stock-transfer/task/source/:skuId"
      element={<StockTransferTaskSourcePage />}
    />
    <Route
      path="/stock-transfer/list/destination"
      element={<StockTransferListDestinationPage />}
    />
    <Route
      path="/stock-transfer/task/destination/:skuId"
      element={<StockTransferTaskDestinationPage />}
    />
    <Route
      path="/stock-transfer/summary"
      element={<StockTransferSummaryPage />}
    />
  </Route>
);
