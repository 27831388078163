import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';

import {
  IOpenBulkDialogData,
  IOpenDialogData,
} from 'pages/dashboard/order-lists/type/open-dialog-state.interface';
import { bulkRetryUpdateFailedStockTransfer } from 'redux-stores/actions';

export const OpenDialogConfig: Record<string, IOpenDialogData | null> = {
  retry: {
    title: 'Retry Update Stock Transfer',
    content: (stockTransferNumber: string): string => {
      return `Are you sure to retry ${stockTransferNumber}?`;
    },
    onConfirm: (
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
      transferNumber: string,
      location?: number,
    ): void => {
      dispatch(
        bulkRetryUpdateFailedStockTransfer({
          transferNumbers: [transferNumber],
          location,
        }),
      );
    },
  },
};

export const OpenBulkDialogConfig: Record<string, IOpenBulkDialogData | null> =
  {
    Retry: {
      title: 'Retry Update Stock Transfers',

      content: (stockTransferNumbers: string[]): string => {
        return `Are you sure to submit retry following stock transfer Tasks: \n\n ${stockTransferNumbers.join(
          '\n',
        )}?`;
      },
      onConfirm: (
        dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
        transferNumbers: string[],
        location?: number,
      ): void => {
        dispatch(
          bulkRetryUpdateFailedStockTransfer({ transferNumbers, location }),
        );
      },
    },
  };
