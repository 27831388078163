import React from 'react';

import { ClickAwayListener, MenuList, MenuItem } from '@mui/material';

import { IStockTransfer } from 'commons/types';

import { RowMenuItemConfig } from '../configs/row-menu-item.config';

interface ActionBtnMenuStockTransferProps {
  selectedTask: IStockTransfer[];
  handleCloseMenu: () => void;
  handleOpen: (name: string) => void;
}

export const ActionBtnMenuStockTransfer: React.FC<
  ActionBtnMenuStockTransferProps
> = ({ selectedTask, handleCloseMenu, handleOpen }) => {
  return (
    <ClickAwayListener onClickAway={handleCloseMenu}>
      <MenuList id="split-button-menu" autoFocusItem>
        {RowMenuItemConfig.map((config) => (
          <MenuItem
            onClick={() => handleOpen(config.name)}
            disabled={config.disabled(selectedTask)}
            key={`menu-item-${config.name}`}
          >
            {config.name}
          </MenuItem>
        ))}
      </MenuList>
    </ClickAwayListener>
  );
};
