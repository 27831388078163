import React from 'react';

import { stockTransferStepJourneyStyle as S } from './step-journey.style';

interface StockTransferStepJourneyProps {
  currentStep: 'source' | 'destination';
}
export const StockTransferStepJourney: React.FC<
  StockTransferStepJourneyProps
> = (props: StockTransferStepJourneyProps) => {
  const { currentStep } = props;
  return (
    <S.StepGridWrapper container>
      <S.StepGrid
        item
        xs={5}
        className={currentStep === 'source' ? 'current-step' : ''}
      >
        <span>1</span>
        Source Bin
      </S.StepGrid>
      <S.ConnectorGrid item xs={2}>
        <S.Connector />
      </S.ConnectorGrid>
      <S.StepGrid
        item
        xs={5}
        className={currentStep === 'destination' ? 'current-step' : ''}
      >
        <span>2</span> Destination Bin
      </S.StepGrid>
    </S.StepGridWrapper>
  );
};
