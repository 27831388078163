import React, { useState } from 'react';

import { Close } from '@mui/icons-material';
import { Drawer, Grid, IconButton } from '@mui/material';

import { ImagePreview } from 'commons/components/ImagePreview';

import { ViewPhotoModalStyle as S } from '../styles';

interface IViewPhotoModalProps {
  openModal: boolean;
  onClose: () => void;
  poNumber: string;
  photos: string[];
}

export const ViewPhotoModal: React.FC<IViewPhotoModalProps> = ({
  openModal,
  onClose,
  poNumber,
  photos,
}) => {
  const [isOpenReview, setIsOpenReview] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const handleOpenReview = (imageURL: string) => {
    setImageSrc(imageURL);
    setIsOpenReview(true);
  };

  const handleCloseReview = () => {
    setIsOpenReview(false);
  };

  return (
    <>
      <Drawer
        anchor="bottom"
        open={openModal}
        onClose={onClose}
        PaperProps={{
          sx: {
            height: '75vh',
            overflow: 'auto',
          },
        }}
      >
        <S.DrawerHeader container>
          <Grid
            container
            xs={11}
            style={{ paddingLeft: '8px', alignItems: 'center' }}
          >
            <S.BoldText>{`Photos of Packing Task ${poNumber}`}</S.BoldText>
          </Grid>
          <Grid container xs={1} alignItems="center" justifyContent="flex-end">
            <IconButton onClick={onClose}>
              <Close fontSize="small" />
            </IconButton>
          </Grid>
        </S.DrawerHeader>
        <Grid
          style={{
            padding: '8px',
            gap: '16px',
            alignItems: 'center',
          }}
          container
        >
          {photos.map((photo) => (
            <S.BasketPhotoWrapper onClick={() => handleOpenReview(photo)}>
              <S.BasketPhoto alt="basket check" src={photo} />
            </S.BasketPhotoWrapper>
          ))}
        </Grid>
      </Drawer>
      <ImagePreview
        openModal={isOpenReview}
        onClose={handleCloseReview}
        imageSrc={imageSrc || ''}
      />
    </>
  );
};
