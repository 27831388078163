import React from 'react';
import { Route } from 'react-router-dom';

import { UserRolesEnum } from 'commons/types/user.roles.enum';
import PrivateRoute from 'commons/utils/privateRoute';
import OrderListPage from 'pages/dashboard/order-lists/OrderList';
import ProductUploadPage from 'pages/dashboard/product-upload/ProductUpload';
import ReplenishListPage from 'pages/dashboard/replenish-list/ReplenishList';
import StockTransferListPage from 'pages/dashboard/stock-transfer-list/StockTransferList';
import SummariesPage from 'pages/dashboard/summaries/Summaries';
import UserListPage from 'pages/dashboard/user-list/UserList';

export const useDashboardRouter = (): React.JSX.Element => (
  <Route
    path="/dashboard"
    element={
      <PrivateRoute
        roles={[
          UserRolesEnum.ADMIN,
          UserRolesEnum.SUPER_ADMIN,
          UserRolesEnum.PICKER_LEAD,
        ]}
      />
    }
  >
    <Route path="/dashboard" element={<SummariesPage />} />
    <Route path="/dashboard/order-item" element={<OrderListPage />} />
    <Route path="/dashboard/users" element={<UserListPage />} />
    <Route path="/dashboard/replenish-item" element={<ReplenishListPage />} />
    <Route
      path="/dashboard/stock-transfer-item"
      element={<StockTransferListPage />}
    />
    <Route path="/dashboard/product-upload" element={<ProductUploadPage />} />
  </Route>
);
