import React from 'react';

import { PRIMARY_RED } from 'commons/styles/colors';
import { IStockTransferItemProps, TableConfig } from 'commons/types';
import { formatDate } from 'commons/utils/formatDate';

export const summaryTableConfig: TableConfig = [
  {
    header: 'Bin',
    key: 'bin',
  },
  {
    header: 'Batch',
    key: 'inventory_number',
  },
  {
    header: 'Expiry Date',
    element: (item: IStockTransferItemProps): React.ReactElement => {
      const date = formatDate(item.expiry_date);
      return <div>{date}</div>;
    },
  },
  {
    header: 'Qty',
    key: 'quantity',
  },
];

export const remainsTableConfig: TableConfig = [
  {
    header: 'Batch',
    key: 'inventory_number',
  },
  {
    header: 'Unplaced Qty',
    element: (item: {
      inventory_number: string;
      quantity: number;
    }): React.ReactElement => {
      return (
        <div style={{ color: PRIMARY_RED, fontWeight: 'bold' }}>
          {item.quantity}
        </div>
      );
    },
  },
];
