import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { GetApp } from '@mui/icons-material';
import { ButtonGroup, Grid, Typography } from '@mui/material';

import { UploaderProps } from './Uploader.interface';
import { S } from './uploader.styles';

export const Uploader: React.FC<UploaderProps> = (props: UploaderProps) => {
  const {
    title,
    multiple = true,
    disabled = false,
    onUpload,
    onDropRejected,
    fileSizePrecision,
    onSelectFiles,
  } = props;
  const [readyFiles, setReadyFile] = useState<File[]>([]);

  const onDropAccepted = useCallback((acceptedFiles: File[]) => {
    if (onSelectFiles) {
      onSelectFiles(acceptedFiles).then(() => {
        setReadyFile([...readyFiles, ...acceptedFiles]);
      });
    } else {
      setReadyFile([...readyFiles, ...acceptedFiles]);
    }
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'text/csv': ['.csv', '.tsv'],
    },
    noClick: true,
    noKeyboard: true,
    multiple,
    disabled,
    onDropAccepted,
    onDropRejected,
  });

  let fileChosenText = 'No file chosen';
  let uploadDisabled = true;
  if (readyFiles && readyFiles.length > 0) {
    const totalSizeInKb =
      readyFiles.reduce((sumOfSizeInBytes: number, file: File) => {
        return sumOfSizeInBytes + file.size;
      }, 0) /
      1024 /
      1024;

    const totalSize = fileSizePrecision
      ? totalSizeInKb.toLocaleString(undefined, {
          minimumFractionDigits: fileSizePrecision,
        })
      : totalSizeInKb.toLocaleString();

    fileChosenText = `${readyFiles.length} file(s) chosen, total: ${totalSize} MB`;
    uploadDisabled = false;
  }

  const handleUploadClicked = async (): Promise<void> => {
    if (!onUpload) return;
    await onUpload(readyFiles);
    setReadyFile([]);
  };

  return (
    <S.UploaderContainer {...getRootProps()}>
      <Grid item sm={12}>
        <S.IconContainer>
          <GetApp color="primary" />
        </S.IconContainer>

        <Typography variant="body1" align="center" color="primary">
          <strong>{title}</strong>
        </Typography>

        <Typography variant="body1" align="center" color="primary">
          or
        </Typography>
        <Typography variant="body1" align="center" color="primary">
          Upload your file below
        </Typography>
      </Grid>
      <S.ActionContainer>
        <input {...getInputProps()} data-testid="file-input" />
        <div>
          <ButtonGroup color="primary">
            <S.ChooseFile onClick={open} data-testid="choose-file-btn">
              Choose File
            </S.ChooseFile>
            <S.ChosenFileDescription onClick={open}>
              {fileChosenText}
            </S.ChosenFileDescription>

            <S.Upload
              disabled={disabled || uploadDisabled}
              onClick={handleUploadClicked}
              data-testid="upload-btn"
            >
              Upload
            </S.Upload>
          </ButtonGroup>
        </div>
      </S.ActionContainer>
    </S.UploaderContainer>
  );
};
