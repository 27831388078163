export enum ReplenishTaskStatusEnum {
  QUEUE = 'queue',
  REPLENISHING = 'replenishing',
  UPDATING = 'updating',
  UPDATE_FAILED = 'update-failed',
  REPLENISHED = 'replenished',
  CANCELLED = 'cancelled',
}

export enum ReplenishSearchCriteriaEnum {
  ALL = 'all',
  QUEUE = 'queue',
  REPLENISHING = 'replenishing',
  UPDATING = 'updating',
  UPDATE_FAILED = 'update-failed',
  REPLENISHED = 'replenished',
  CANCELLED = 'cancelled',
}
