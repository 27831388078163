import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';

import {
  IOpenBulkDialogData,
  IOpenDialogData,
} from 'pages/dashboard/order-lists/type/open-dialog-state.interface';
import {
  bulkPrioritizeReplenishTask,
  bulkRetryUpdateFailedReplenish,
  bulkUnPrioritizeReplenishTask,
  prioritizeReplenishTask,
  retryUpdateFailedOrderReplenish,
  unPrioritizeReplenishTask,
} from 'redux-stores/actions';

export const OpenDialogConfig: Record<string, IOpenDialogData | null> = {
  prioritize: {
    title: 'Prioritize Replenish',
    content: (replenishNumber: string): string => {
      return `Are you sure to prioritize ${replenishNumber}?`;
    },
    onConfirm: (
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
      replenishNumber: string,
      location?: number,
    ): void => {
      dispatch(prioritizeReplenishTask({ replenishNumber, location }));
    },
  },
  unprioritize: {
    title: 'Unprioritize Replenish',
    content: (replenishNumber: string): string => {
      return `Are you sure to remove priority ${replenishNumber}?`;
    },
    onConfirm: (
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
      replenishNumber: string,
      location?: number,
    ): void => {
      dispatch(unPrioritizeReplenishTask({ replenishNumber, location }));
    },
  },
  retry: {
    title: 'Retry Update Replenish',
    content: (replenishNumber: string): string => {
      return `Are you sure to retry ${replenishNumber}?`;
    },
    onConfirm: (
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
      replenishNumber: string,
      location?: number,
    ): void => {
      dispatch(retryUpdateFailedOrderReplenish({ replenishNumber, location }));
    },
  },
};

export const OpenBulkDialogConfig: Record<string, IOpenBulkDialogData | null> =
  {
    Prioritize: {
      title: 'Prioritize Replenish',
      content: (replenishNumbers: string[]): string => {
        return `Are you sure to prioritize following Replenishs: \n\n ${replenishNumbers.join(
          '\n',
        )}?`;
      },
      onConfirm: (
        dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
        replenishNumbers: string[],
        location?: number,
      ): void => {
        dispatch(bulkPrioritizeReplenishTask({ replenishNumbers, location }));
      },
    },
    Unprioritize: {
      title: 'Unprioritize Replenish',
      content: (replenishNumbers: string[]): string => {
        return `Are you sure to unprioritize following Replenishs: \n\n ${replenishNumbers.join(
          '\n',
        )}?`;
      },
      onConfirm: (
        dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
        replenishNumbers: string[],
        location?: number,
      ): void => {
        dispatch(bulkUnPrioritizeReplenishTask({ replenishNumbers, location }));
      },
    },
    Retry: {
      title: 'Retry Update Replenish',
      content: (replenishNumbers: string[]): string => {
        return `Are you sure to submit retry following Replenish Tasks: \n\n ${replenishNumbers.join(
          '\n',
        )}?`;
      },
      onConfirm: (
        dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
        replenishNumbers: string[],
        location?: number,
      ): void => {
        dispatch(
          bulkRetryUpdateFailedReplenish({ replenishNumbers, location }),
        );
      },
    },
  };
