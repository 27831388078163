import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Brightness1 } from '@mui/icons-material';
import { Grid } from '@mui/material';

import { Header } from 'commons/components/Header';
import { TableComponent } from 'commons/components/Table';
import { PROGRESS_ICON_COLOR } from 'commons/styles/colors';
import { IStockTransfer } from 'commons/types';
import {
  addBinInfoDestinationAction,
  getStockTransferAction,
} from 'redux-stores/actions';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import { BinSkuSearch, StockTransferStepJourney } from './components';
import { destinationConfig } from './config';
import { stockTransferListStyle as S } from './StockTransferList.style';

export const StockTransferListDestinationPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { stockTransfer } = useSelector(
    (state: RootReducerInterface) => state.stockTransfer,
  );

  const [isValidatedNextStep, setValidatedNextStep] = useState(false);

  const handleBackToSource = (): void => {
    navigate('/stock-transfer/list/source');
  };
  const handleNextStep = (): void => {
    navigate('/stock-transfer/summary');
  };

  const handleAddSkuBin = (sku: string, bin: string): void => {
    if (sku && bin) {
      dispatch(addBinInfoDestinationAction({ skuCode: sku, binNumber: bin }));
    }
  };

  useEffect(() => {
    dispatch(getStockTransferAction());
  }, [dispatch]);

  useEffect(() => {
    if (Object.values(stockTransfer).length === 0) {
      navigate('/stock-transfer/list/source');
    }
  }, [dispatch, navigate, stockTransfer]);

  useEffect(() => {
    const stockTransferList = Object.values(stockTransfer);

    for (let i = 0; i < stockTransferList.length; i += 1) {
      const { source_bin: sourceBin, destination_bin: destinationBin } =
        stockTransferList[i];

      const sourceTotalQty = sourceBin.reduce(
        (total, item) => total + item.quantity,
        0,
      );
      const destinationTotalQty = destinationBin.reduce(
        (total, item) => total + item.quantity,
        0,
      );

      if (sourceTotalQty !== destinationTotalQty) {
        setValidatedNextStep(false);
        return;
      }
    }

    setValidatedNextStep(true);
  }, [stockTransfer]);

  const renderStockTransferData = (stockTransferItem: IStockTransfer) => {
    const skuButtonText =
      stockTransferItem.destination_bin.length === 0 ? 'START' : 'EDIT';
    const sourceQty = stockTransferItem.source_bin.reduce(
      (total, item) => total + item.quantity,
      0,
    );
    const destinationQty = stockTransferItem.destination_bin.reduce(
      (total, item) => total + item.quantity,
      0,
    );
    const colorProgress =
      destinationQty === 0
        ? PROGRESS_ICON_COLOR[0]
        : sourceQty === destinationQty
        ? PROGRESS_ICON_COLOR[1]
        : PROGRESS_ICON_COLOR[2];
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Grid container>
            <S.SKUGridWrapper container>
              <S.SKUGrid item xs={8}>
                SKU {stockTransferItem.sku_code}
                <S.ProgressSpan>
                  <Brightness1 style={{ fontSize: 11, color: colorProgress }} />{' '}
                  {destinationQty}/{sourceQty}
                </S.ProgressSpan>
              </S.SKUGrid>
              <S.SKUButtonGrid item xs={4}>
                <S.SecondaryButton
                  onClick={() => {
                    navigate(
                      `/stock-transfer/task/destination/${stockTransferItem.sku_code}`,
                    );
                  }}
                >
                  {skuButtonText}
                </S.SecondaryButton>
              </S.SKUButtonGrid>
            </S.SKUGridWrapper>

            <S.TableInfoGridWrapper container>
              <S.TableInfoGrid item xs={12}>
                <TableComponent
                  config={destinationConfig}
                  data={stockTransferItem.bin_info_destination}
                />
              </S.TableInfoGrid>
            </S.TableInfoGridWrapper>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Header
        title="Stock Transfer List"
        prevPageHandler={handleBackToSource}
      />

      <S.ContentWrapper>
        <StockTransferStepJourney currentStep="destination" />
        <BinSkuSearch onAddClicked={handleAddSkuBin} />

        {Object.values(stockTransfer).map((stockTransferItem) =>
          renderStockTransferData(stockTransferItem),
        )}
      </S.ContentWrapper>

      <S.FooterWrapper>
        <S.PrimaryButton
          onClick={handleNextStep}
          disabled={!isValidatedNextStep}
        >
          PROCEED TO NEXT STEP
        </S.PrimaryButton>
      </S.FooterWrapper>
    </>
  );
};
