import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { UserRolesEnum } from 'commons/types/user.roles.enum';
import PrivateRoute from 'commons/utils/privateRoute';
import AssignSKUPage from 'pages/replenish/assign-replenish-task/AssignSKUPage';
import {
  ReplenishListDestinationPage,
  ReplenishListSourcePage,
} from 'pages/replenish/replenish-list';
import { ReplenishSummaryPage } from 'pages/replenish/replenish-summary';
import {
  ReplenishTaskDestinationPage,
  ReplenishTaskSourcePage,
} from 'pages/replenish/replenish-task';

export const useReplenishRouter = (): React.JSX.Element => (
  <Route
    path="/replenish"
    element={
      <PrivateRoute
        roles={[
          UserRolesEnum.SUPER_ADMIN,
          UserRolesEnum.ADMIN,
          UserRolesEnum.REPLENISH,
        ]}
      />
    }
  >
    <Route path="/replenish" element={<Navigate to="/replenish/assign" />} />
    <Route path="/replenish/assign" element={<AssignSKUPage />} />
    <Route
      path="/replenish/list/source"
      element={<ReplenishListSourcePage />}
    />
    <Route
      path="/replenish/list/destination"
      element={<ReplenishListDestinationPage />}
    />
    <Route
      path="/replenish/task/source/:skuId"
      element={<ReplenishTaskSourcePage />}
    />
    <Route
      path="/replenish/task/destination/:skuId"
      element={<ReplenishTaskDestinationPage />}
    />
    <Route path="/replenish/summary" element={<ReplenishSummaryPage />} />
  </Route>
);
