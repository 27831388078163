import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { useAuthRouter } from './auth.route';
import { useDashboardRouter } from './dashboard.route';
import { useHomeRouter } from './home.route';
import { usePackTaskRouter } from './pack-task.route';
import { usePickTaskRouter } from './pick-task.route';
import { usePrintTemplateRouter } from './print-template.route';
import { useReplenishRouter } from './replenish.route';
import { useStockTransferRouter } from './stock-transfer.route';

export const AppRouter = (): React.JSX.Element => (
  <Routes>
    <Route path="/" element={<Navigate to="/login" />} />
    {useAuthRouter()}
    {useHomeRouter()}
    {useReplenishRouter()}
    {usePickTaskRouter()}
    {usePackTaskRouter()}
    {useDashboardRouter()}
    {usePrintTemplateRouter()}
    {useStockTransferRouter()}
    <Route path="/not-found" element={<div>Not found</div>} />
    <Route path="/*" element={<Navigate to="/login" />} />
  </Routes>
);
