import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ErrorOutlineOutlined } from '@mui/icons-material';
import { Grid } from '@mui/material';

import { Header } from 'commons/components/Header';
import * as colors from 'commons/styles/colors';
import { IStockTransferWithSummary } from 'commons/types';
import {
  completeStockTransferAction,
  getStockTransferAction,
} from 'redux-stores/actions';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import { StockTransferSkuList } from './components';
import { StockTransferSummaryStyle as S } from './StockTransferSummary.style';

export const StockTransferSummaryPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { stockTransfer } = useSelector(
    (state: RootReducerInterface) => state.stockTransfer,
  );

  useEffect(() => {
    dispatch(getStockTransferAction());
  }, [dispatch]);

  const { completeSku, incompleteSku } = useMemo(() => {
    const stockTransferList = Object.values(stockTransfer);
    const _completeSku: IStockTransferWithSummary[] = [];
    const _incompleteSku: IStockTransferWithSummary[] = [];

    stockTransferList.forEach((stockTransferItem) => {
      const batchQty: Record<string, { source: number; destination: number }> =
        {};
      const totalQty = { source: 0, destination: 0 };
      const batchRemainsQty: { inventory_number: string; quantity: number }[] =
        [];
      let isCompleted = true;

      stockTransferItem.source_bin.forEach(
        ({ inventory_number: batch, quantity }) => {
          if (!batchQty[batch]) {
            batchQty[batch] = { source: 0, destination: 0 };
          }
          batchQty[batch].source += quantity;
          totalQty.source += quantity;
        },
      );
      stockTransferItem.destination_bin.forEach(
        ({ inventory_number: batch, quantity }) => {
          if (!batchQty[batch]) {
            batchQty[batch] = { source: 0, destination: 0 };
          }
          batchQty[batch].destination += quantity;
          totalQty.destination += quantity;
        },
      );
      if (totalQty.source !== totalQty.destination) {
        isCompleted = false;
      }
      const batchList = Object.keys(batchQty);
      for (let i = 0; i < batchList.length; i += 1) {
        const batch = batchList[i];
        if (batchQty[batch].source !== batchQty[batch].destination) {
          if (batchQty[batch].source !== batchQty[batch].destination) {
            isCompleted = false;
            batchRemainsQty.push({
              inventory_number: batch,
              quantity: batchQty[batch].source - batchQty[batch].destination,
            });
          }
        }
      }

      if (isCompleted) {
        _completeSku.push({
          ...stockTransferItem,
          totalQty,
          batchQty,
        });
      } else {
        _incompleteSku.push({
          ...stockTransferItem,
          batchQty,
          totalQty,
          batchRemainsQty,
        });
      }
    });

    return { completeSku: _completeSku, incompleteSku: _incompleteSku };
  }, [stockTransfer]);

  const handleBackToDestination = (): void => {
    navigate('/stock-transfer/list/destination');
  };

  const handleCompleteStockTransfer = (): void => {
    const transferNumbers = Object.values(stockTransfer).map(
      (item) => item.transfer_number,
    );
    dispatch(
      completeStockTransferAction({
        transfer_numbers: transferNumbers,
        postAction: () => navigate('/stock-transfer/list/source'),
      }),
    );
  };

  return (
    <>
      <Header
        title="Stock Transfer Summary"
        prevPageHandler={handleBackToDestination}
      />

      <S.ContentWrapper>
        {/* incomplete */}
        {incompleteSku.length > 0 && (
          <StockTransferSkuList
            stockTransfer={incompleteSku}
            headerText="Incomplete Stock Transfer"
            headerColor={colors.PRIMARY_YELLOW}
            headerIcon={<ErrorOutlineOutlined />}
            defaultExpanded
          />
        )}

        {/* complete */}
        {completeSku.length > 0 && (
          <StockTransferSkuList
            stockTransfer={completeSku}
            headerText="Complete Stock Transfer"
          />
        )}
        <S.BlankWhiteSpace />
      </S.ContentWrapper>

      {/* Footer */}
      <S.FooterWrapper>
        <Grid container>
          <Grid item xs={6}>
            <S.SecondaryButton onClick={handleBackToDestination}>
              GO BACK
            </S.SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <S.PrimaryButton
              disabled={incompleteSku.length > 0}
              onClick={handleCompleteStockTransfer}
            >
              COMPLETE TASK
            </S.PrimaryButton>
          </Grid>
        </Grid>
      </S.FooterWrapper>
    </>
  );
};
