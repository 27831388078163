import { CsvTemplateBuilder } from 'commons/utils/CSVTemplateBuilder';

const ProductBinQtyBulkUploadTemplate = new CsvTemplateBuilder(
  'product-bin-qty-bulk-upload',
);
ProductBinQtyBulkUploadTemplate.setHeader([
  'sku_code',
  'name',
  'uom',
  'min_bin_qty',
  'max_bin_qty',
]);

ProductBinQtyBulkUploadTemplate.addRowValues([
  'ID000000-1',
  'Product 1',
  'Dus 5 Tablet',
  '10',
  '100',
]);
ProductBinQtyBulkUploadTemplate.addRowValues([
  'ID000001-1',
  'Product 2',
  'Dus 10 Tablet',
  '12',
  '120',
]);
ProductBinQtyBulkUploadTemplate.addRowValues([
  'ID000002-1',
  'Product 3',
  'Dus 15 Tablet',
  '15',
  '150',
]);

export { ProductBinQtyBulkUploadTemplate };
