import { Grid } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';
import * as colors from 'commons/styles/colors';

class StockTransferSummaryPageStyle extends CommonComponentStyle {
  HeaderWrapper = styled.div`
    padding: 8px 0;
    text-align: center;
  `;

  Header = styled.p<{ color?: string }>`
    font-size: 18px;
    font-weight: 700;
    display: inline-flex;
    margin-bottom: 0;
    color: ${(props): string => props.color || colors.PRIMARY_GREEN};
  `;

  SubHeader = styled.p`
    margin-top: 0;
  `;

  SKUSummaryWrapper = styled.div`
    padding: 8px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  `;

  SummaryName = styled.p`
    font-size: 14px !important;
    margin: 8px;
  `;

  SummaryValue = styled.p`
    font-size: 14px !important;
    font-weight: 700 !important;
    text-align: right !important;
    margin: 8px;
  `;

  ItemContentWrapper = styled(Grid)`
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    &.table-content {
      padding: 8px 16px;
      font-size: 14px;
    }
  `;

  SubHeaderText = styled.p`
    font-weight: 700;
    font-size: 14px;
    color: ${this.colors.PRIMARY_GRAY};
  `;
}

export const StockTransferSummaryStyle = new StockTransferSummaryPageStyle();
