import React from 'react';

import { DebouncedState } from 'use-debounce';

import { CriteriaOption } from 'commons/components/SearchRelated';
import {
  OrderStatusEnum,
  ReplenishTaskStatusEnum,
  OrderSearchCriteriaEnum,
  UserStatusEnum,
  StockTransferStatusEnum,
  ReplenishSearchCriteriaEnum,
  StockTransferSearchCriteriaEnum,
} from 'commons/enums';

import { PickingTask } from './pickTask.interface';
import { ReplenishTask } from './replenishTask.interface';
import { IStockTransfer } from './stockTransfer.interface';
import { User } from './user.interface';

export interface ListPageProps {
  updateListSearchDebounce: DebouncedState<
    (_searchParams: any, location: any, _dispatch: any) => void
  >;
  pageSize?: string;
  searchCriteria?: CriteriaOption[];
}

export interface ListFilterCardProps {
  statusArrayText: { key: string; text: string }[];
  statusFilter:
    | UserStatusEnum
    | OrderStatusEnum
    | ReplenishTaskStatusEnum
    | StockTransferStatusEnum
    | OrderSearchCriteriaEnum
    | ReplenishSearchCriteriaEnum
    | StockTransferSearchCriteriaEnum
    | null;
  handleStatusFilter: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any,
  ) => void;
  searchCriteria?: CriteriaOption[];
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export interface RowTitleProps {
  title: string;
  sortField?: string;
  isCentered?: boolean;
}

export interface DefaultSortProps {
  sortField: string;
  sortOrder: SortOrder;
}

export interface DataTableProps {
  hideCheckbox?: boolean;
  handleCheckAll?: () => void;
  checkAll?: boolean;
  rowTitles: RowTitleProps[];
  defaultSort?: DefaultSortProps;
}

export interface ActionRowProps {
  dataName?: string;
  selectedData?: User[] | PickingTask[] | ReplenishTask[] | IStockTransfer[];
  anchorEl?: HTMLElement | null;
  openMenu?: boolean;
  handleClickMenu?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  pageSizeOptions?: number[];
}

export interface ChooseActionBtnProps {
  selectedData: User[] | PickingTask[] | ReplenishTask[] | IStockTransfer[];
  anchorEl?: HTMLElement | null;
  openMenu?: boolean;
  handleClickMenu?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  dataName?: string;
}
