import { TextField } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';

class BinSkuSearchStyle extends CommonComponentStyle {
  SearchBar = styled(TextField)`
    width: 100%;
    margin-bottom: 8px !important;
  `;

  AddButton = styled(this.PrimaryButton)`
    width: 100% !important;
  `;
}
export const binSkuSearchStyle = new BinSkuSearchStyle();
