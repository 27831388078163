import React, { useState } from 'react';

import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';

import * as S from 'commons/components/Table/Table.styles';
import { IFileImport } from 'commons/types';
import { RowTitleProps } from 'commons/types/listPageData.interface';
import { DataTable, TableActionRow } from 'pages/dashboard/commons/components';

const rowTitles: RowTitleProps[] = [
  { title: 'Upload Date' },
  { title: 'Name' },
  { title: 'Valid Data' },
  { title: 'Invalid Data' },
  { title: 'Status' },
];

const invalidDataHeaders = ['location', 'errors'];

const dateFormatter = (date: Date): string => {
  return new Date(date).toLocaleDateString(undefined, {
    hour12: true,
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
};

const Row: React.FC<{
  data: IFileImport;
}> = ({ data }) => {
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);

  const handleDetails = (): void => {
    setDetailsOpen(!detailsOpen);
  };

  return (
    <>
      {/* Invalid data details modal */}
      <Dialog
        maxWidth="sm"
        fullWidth
        onClose={handleDetails}
        open={detailsOpen}
      >
        <DialogTitle>Invalid Data Details</DialogTitle>
        <IconButton
          onClick={handleDetails}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          {data.error_file && data.error_file[0] && (
            <S.TableWrapper
              style={{
                overflow: 'scroll-x',
              }}
            >
              <S.TableHeader>
                <tr>
                  {invalidDataHeaders.map((header: string) => {
                    return (
                      <S.TableHeaderRow key={header}>
                        <Typography variant="body2">{header}</Typography>
                      </S.TableHeaderRow>
                    );
                  })}
                  <S.TableHeaderRow />
                </tr>
              </S.TableHeader>
              <S.TableBody>
                {data.error_file.map((error) => {
                  return (
                    <S.TableRow>
                      <S.TableColumn>
                        {error.identifier
                          ? Object.entries(error.identifier)
                              .map(([key, value]) => `${key}: ${value}`)
                              .join(', ')
                          : data.file_name}
                      </S.TableColumn>
                      <S.TableColumn>
                        {error.errors
                          ? Object.entries(error.errors).map(([key, value]) => {
                              return <div>{`${key}: ${value}`}</div>;
                            })
                          : null}
                      </S.TableColumn>
                    </S.TableRow>
                  );
                })}
              </S.TableBody>
            </S.TableWrapper>
          )}
        </DialogContent>
      </Dialog>

      {/* Table row */}
      <S.TableRow>
        <S.TableColumn>
          {data.uploaded_at && dateFormatter(data.uploaded_at)}
        </S.TableColumn>
        <S.TableColumn>{data.file_name}</S.TableColumn>
        <S.TableColumn>{data.valid_rows}</S.TableColumn>
        <S.TableColumn>{data.invalid_rows}</S.TableColumn>
        <S.TableColumn style={{ textTransform: 'capitalize' }}>
          {data.status}
        </S.TableColumn>
        <S.TableColumn style={{ textAlign: 'center' }}>
          {data.error_file && data.error_file.length > 0 ? (
            <Button
              onClick={handleDetails}
              variant="outlined"
              style={{ marginLeft: '8px' }}
            >
              Invalid Data Details
            </Button>
          ) : null}
        </S.TableColumn>
      </S.TableRow>
    </>
  );
};

export const FileImportHistoryTable: React.FC<{
  data: IFileImport[] | never[];
}> = (props) => {
  const { data } = props;

  return (
    <Grid item sm={12}>
      <br />
      <Typography variant="subtitle1">
        <strong>File Import History</strong>
      </Typography>
      <TableActionRow pageSizeOptions={[5, 10, 20]} />
      <br />
      <DataTable hideCheckbox rowTitles={rowTitles}>
        <S.TableBody>
          {data.map((item) => (
            <Row data={item} />
          ))}
        </S.TableBody>
      </DataTable>
      <br />
    </Grid>
  );
};
