import { CsvTemplateBuilder } from 'commons/utils/CSVTemplateBuilder';

const ProductPrioritizationCategoryBulkUploadTemplate = new CsvTemplateBuilder(
  'product-prioritization-category-bulk-upload',
);
ProductPrioritizationCategoryBulkUploadTemplate.setHeader([
  'sku_code',
  'warehouse_id',
  'category',
]);

ProductPrioritizationCategoryBulkUploadTemplate.addRowValues([
  'ID000000-1',
  '1994',
  'a',
]);
ProductPrioritizationCategoryBulkUploadTemplate.addRowValues([
  'ID000001-1',
  '1993',
  'b',
]);
ProductPrioritizationCategoryBulkUploadTemplate.addRowValues([
  'ID000002-1',
  '1994',
  'c',
]);

export { ProductPrioritizationCategoryBulkUploadTemplate };
