/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { CompanyState, ICompanyInfo } from 'commons/types/company.interface';
import { ReducerKeys } from 'redux-stores/enums/reducerKey.enum';

const initialState: CompanyState = {
  company: null,
};

const companyInfoSlice = createSlice({
  name: ReducerKeys.PackingTask,
  initialState,
  reducers: {
    setCompanyInfo(
      state: CompanyState,
      action: { type: string; payload: ICompanyInfo },
    ) {
      state.company = action.payload;
    },
  },
});

export const { setCompanyInfo } = companyInfoSlice.actions;

export default companyInfoSlice.reducer;
