import { Grid } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';

class ViewPhotoModalStyles extends CommonComponentStyle {
  DrawerHeader = styled(Grid)`
    background-color: ${this.colors.LIGHTER_GRAY};
    padding: 8px;
  `;

  BoldText = styled.div`
    font-weight: 700;
    width: fit-content;
  `;

  BasketPhotoWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
  `;

  BasketPhoto = styled.img`
    width: 120px;
    height: 120px;
    border-radius: 8px;
  `;
}

export const ViewPhotoModalStyle = new ViewPhotoModalStyles();
