import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  Button,
  ButtonGroup,
  Grid,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import { useUpdateQueryParams } from 'commons/components/SearchRelated/SearchWithCriteria/hooks';
import { ActionRowProps } from 'commons/types/listPageData.interface';

import { ChooseActionBtn } from './ChooseActionBtn.component';

const defaultPageSizeOptions = [50, 100, 150];

export const TableActionRow: React.FC<
  React.PropsWithChildren<ActionRowProps>
> = (params) => {
  const {
    children,
    dataName = '',
    selectedData = [],
    anchorEl,
    openMenu,
    handleClickMenu,
    pageSizeOptions = defaultPageSizeOptions,
  }: React.PropsWithChildren<ActionRowProps> = params;

  const { updateQueryParams } = useUpdateQueryParams();
  const [searchParams] = useSearchParams({
    page: '1',
  });
  const [maxItemShown, setMaxItemShown] = useState<number>(pageSizeOptions[0]);
  const [page, setPage] = useState<number>(1);

  const handleMaxItemShownChange = (event: SelectChangeEvent<number>): void => {
    const pageSize = Number(event.target.value);
    setMaxItemShown(pageSize);
    updateQueryParams({ page_size: `${pageSize}` });
  };

  const handleNextPage = (): void => {
    const newPage = page + 1;
    setPage(newPage);
    updateQueryParams({ page: `${newPage}` });
  };

  const handlePrevPage = (): void => {
    const newPage = page - 1;
    if (page <= 0) {
      return;
    }
    setPage(newPage);
    updateQueryParams({ page: `${newPage}` });
  };

  const handleJumpPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newJumpPage = Number(event.target.value);
    if (!newJumpPage || newJumpPage <= 0) {
      return;
    }
    setPage(newJumpPage);
    updateQueryParams({ page: `${newJumpPage}` });
  };

  useEffect(() => {
    const newPage = searchParams.get('page');
    if (newPage) {
      setPage(parseInt(newPage, 10));
    }
  }, [searchParams]);

  return (
    <Grid container>
      {dataName ? (
        <ChooseActionBtn
          selectedData={selectedData || []}
          anchorEl={anchorEl}
          openMenu={openMenu}
          handleClickMenu={handleClickMenu}
          dataName={dataName}
        >
          {children}
        </ChooseActionBtn>
      ) : (
        <Grid item xs={3} />
      )}
      <Grid item xs={2} />
      <Grid item xs={1}>
        <Typography
          variant="body2"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          Current:
        </Typography>
        <Typography variant="body1">
          {maxItemShown * page - (maxItemShown - 1)}-{maxItemShown * page}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography
          variant="body2"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          Table size:
        </Typography>
        <Select value={maxItemShown} onChange={handleMaxItemShownChange}>
          {pageSizeOptions.map((option) => {
            return <MenuItem value={option}>{`${option} rows`}</MenuItem>;
          })}
        </Select>
      </Grid>
      <Grid item xs={2}>
        <Typography
          variant="body2"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          Jump to page:
        </Typography>
        <Input
          type="number"
          value={page}
          onChange={handleJumpPage}
          inputMode="numeric"
        />
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'right' }}>
        <ButtonGroup variant="outlined">
          <Button onClick={handlePrevPage} disabled={page === 1}>
            <Typography variant="button">Prev</Typography>
          </Button>
          <Button onClick={handleNextPage}>
            <Typography variant="button">Next</Typography>
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};
