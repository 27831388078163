import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { Grid, TextField } from '@mui/material';

import ConfirmationDialog from 'commons/components/ConfirmationDialog/ConfirmationDialog.component';
import { Header } from 'commons/components/Header';
import { TableComponent } from 'commons/components/Table';
import { OrderStatusEnum } from 'commons/enums';
import { IPackingTask } from 'commons/types/packingTask.interface';
import {
  completeChecking,
  getPackerOnProgressPackingTaskAllowOnUpdate,
  getPackingTaskByBasket,
} from 'redux-stores/actions/packingTask.action';
import { RootReducerInterface } from 'redux-stores/reducers';
import { setPackingTask } from 'redux-stores/reducers/packingTaskReducer';
import { AppDispatch } from 'redux-stores/store';

import { CheckBasketStyle as S } from './CheckBasket.styles';
import { BasketInfos } from './components';
import { poDataConfig } from './table-configs';

const CheckBasketPage: React.FC = () => {
  const { packingTask } = useSelector(
    (state: RootReducerInterface) => state.packingTask,
  );
  const dispatch = useDispatch<AppDispatch>();

  const [basket, setBasket] = useState<number>(0);
  const [currentPackingTask, setCurrentPackingTask] =
    useState<IPackingTask | null>(null);
  const [openChangePoModal, setOpenChangePoModal] = useState<boolean>(false);
  const [disableContent, setDisableContent] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const onFieldChange = (value: string): void => {
    if (value === '') {
      setBasket(0);
      return;
    }
    const basketValue =
      typeof value === 'string' ? parseInt(value, 10) : (value as number);
    setBasket(basketValue);
  };

  const handleClickCheckDetails = (): void => {
    dispatch(getPackingTaskByBasket(basket)).then(() => setBasket(0));
  };

  const getTotalQtyBySkuCode = (skuCode: string): number => {
    const qtyTotal = currentPackingTask?.basket_infos
      .flatMap((info) =>
        info.items.flatMap((item) => {
          if (item.sku_code === skuCode) {
            return item.total_qty_picked;
          }
          return 0;
        }),
      )
      .reduce((sum, data) => sum + data, 0);
    return qtyTotal ?? 0;
  };

  const goToPrintPage = (): void => {
    const poNumber = searchParams.get('poNumber') ?? '';
    navigate({
      pathname: `/pack-task/print/`,
      search: createSearchParams({
        poNumber,
      }).toString(),
    });
  };

  const handleStartPacking = (): void => {
    const poNumber = searchParams.get('poNumber') ?? '';
    if (packingTask?.picking_task.status === OrderStatusEnum.CHECKING) {
      dispatch(completeChecking({ poNumber })).then(() => {
        goToPrintPage();
      });
    } else {
      goToPrintPage();
    }
  };

  const handlePoChangeModalOnConfirm = () => {
    setCurrentPackingTask(packingTask);
    setOpenChangePoModal(false);
  };

  // return state with currentPackingTask data
  const handlePoChangeModalOnClose = () => {
    setOpenChangePoModal(false);
    dispatch(setPackingTask(currentPackingTask));
  };

  useEffect(() => {
    const poNumber = searchParams.get('poNumber') ?? '';
    if (poNumber !== '') {
      dispatch(getPackerOnProgressPackingTaskAllowOnUpdate({ poNumber }));
    }
  }, [dispatch]);

  useEffect(() => {
    // check packingTask po number with currentPackingTask value
    // if different, set modal open for data changing
    if (packingTask) {
      if (
        currentPackingTask &&
        currentPackingTask.po_number !== packingTask.po_number
      ) {
        setOpenChangePoModal(true);
      } else {
        setCurrentPackingTask(packingTask);
      }
    }
  }, [packingTask]);

  useEffect(() => {
    if (currentPackingTask) {
      // disable content if status is not checking
      setDisableContent(
        currentPackingTask.picking_task.status !== OrderStatusEnum.CHECKING,
      );
      setSearchParams({ poNumber: currentPackingTask.po_number });
    } else {
      setDisableContent(true);
    }
  }, [currentPackingTask, setSearchParams]);

  const isAbleToStartPack = (): boolean => {
    if (currentPackingTask) {
      const isAllChecked = currentPackingTask.basket_infos.every((basketInfo) =>
        basketInfo.items.every((item) => item.check_passed),
      );
      const isAllPhotoTaken = currentPackingTask.basket_infos.every(
        (basketInfo) => basketInfo.photo_url.length > 0,
      );
      return isAllChecked && isAllPhotoTaken;
    } else {
      return false;
    }
  };

  const handleBackToOutboundPage = (): void => {
    navigate('/home/outbound');
  };

  return (
    <div>
      <Header
        title="Check Baskets"
        prevPageHandler={handleBackToOutboundPage}
      />
      <Grid container style={{ padding: '16px 0' }}>
        <Grid xs={9}>
          <TextField
            placeholder="Basket Number"
            fullWidth
            variant="outlined"
            type="number"
            value={basket !== 0 ? basket : ''}
            onChange={(event) => onFieldChange(event.target.value)}
          />
        </Grid>
        <Grid xs={3} style={{ textAlign: 'center' }}>
          <S.PrimaryButton
            onClick={() => handleClickCheckDetails()}
            disabled={basket === 0}
          >
            CHECK DETAILS
          </S.PrimaryButton>
        </Grid>
      </Grid>
      {currentPackingTask ? (
        <div style={{ margin: '16px 0' }}>
          <TableComponent config={poDataConfig} data={[currentPackingTask]} />
        </div>
      ) : null}
      <S.ContentWrapper>
        {currentPackingTask?.basket_infos
          .filter((info) => info.is_scanned)
          .map((data, index) => (
            <BasketInfos
              key={data.basket}
              data={data}
              poNumber={currentPackingTask.po_number}
              basketIndex={index}
              getTotalQtyBySkuCode={getTotalQtyBySkuCode}
              disableContent={disableContent}
            />
          ))}
        <S.BlankWhiteSpace />
      </S.ContentWrapper>
      <S.FooterWrapper>
        <Grid container>
          <Grid item xs={12}>
            <S.PrimaryButton
              disabled={!isAbleToStartPack()}
              onClick={handleStartPacking}
            >
              START PACKING
            </S.PrimaryButton>
          </Grid>
        </Grid>
      </S.FooterWrapper>

      {/* Modal for change PO over scan basket number */}
      <ConfirmationDialog
        title="Order Change"
        content={`Basket number scanned will change order from <b>${currentPackingTask?.po_number}</b> to <b>${packingTask?.po_number}</b>. Do you wish to change order checking?`}
        onClose={handlePoChangeModalOnClose}
        onConfirm={handlePoChangeModalOnConfirm}
        openModal={openChangePoModal}
        callOnCloseOnHandleConfirm={false}
      />
    </div>
  );
};

export default CheckBasketPage;
