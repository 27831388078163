/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { Dialog } from '@mui/material';

import { IStockTransfer } from 'commons/types';

import * as S from './SKUDialog.styles';

interface IRemoveStockTransferProps {
  task?: IStockTransfer | null;
  onClose: () => void;
  openModal: boolean;
  onConfirm: (task: IStockTransfer) => void;
}

const RemoveStockTransferDialog: React.FC<IRemoveStockTransferProps> = ({
  task,
  onClose,
  openModal,
  onConfirm,
}) => {
  // immediate return if null
  if (!task) {
    onClose();
    return <div />;
  }

  return (
    <Dialog onClose={onClose} open={openModal}>
      <S.TitleWrapper>
        <S.Title>REMOVE SKU</S.Title>
      </S.TitleWrapper>

      <S.Text>
        Are you sure to remove SKU <b>{task.sku_code}</b> from your list?
      </S.Text>

      {/* Footer */}
      <S.FooterWrapper>
        <S.CancelButton onClick={onClose}>NO</S.CancelButton>
        <S.OKButton onClick={() => onConfirm(task)}>YES</S.OKButton>
      </S.FooterWrapper>
    </Dialog>
  );
};

export default RemoveStockTransferDialog;
