import { TableConfig } from 'commons/types';

export const sourceConfig: TableConfig = [
  {
    header: 'Bin Number',
    key: 'bin_number',
  },
  {
    header: 'Type',
    key: 'type',
  },
  {
    header: 'Min Qty',
    key: 'min_qty',
  },
  {
    header: 'Max Qty',
    key: 'max_qty',
  },
];
