import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';

import { StockTransferStatusEnum } from 'commons/enums';
import { IStockTransfer } from 'commons/types';
import { bulkRetryUpdateFailedStockTransfer } from 'redux-stores/actions';

export const RowMenuItemConfig = [
  {
    name: 'Retry',

    onClick: (
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
      transferNumber: string,
      location?: number,
    ): void => {
      dispatch(
        bulkRetryUpdateFailedStockTransfer({
          transferNumbers: [transferNumber],
          location,
        }),
      );
    },
    disabled: (selected: IStockTransfer[]): boolean => {
      for (let i = 0; i < selected.length; i += 1) {
        if (selected[i].status === StockTransferStatusEnum.UPDATE_FAILED)
          return false;
      }
      return true;
    },
  },
];
