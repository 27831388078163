export enum OrderStatusEnum {
  QUEUE = 'queue',
  PICKING = 'picking',
  PICKED = 'picked',
  CHECKING = 'checking',
  PACKING = 'packing',
  UPDATING = 'updating',
  UPDATE_FAILED = 'update-failed',
  PACKED = 'packed',
  CANCELLED = 'cancelled',
}

export enum OrderSearchCriteriaEnum {
  ALL = 'all',
  QUEUE = 'queue',
  ASSIGNED = 'assigned',
  PICKING = 'picking',
  PICKED = 'picked',
  CHECKING = 'checking',
  UPDATING = 'updating',
  UPDATE_FAILED = 'update-failed',
  PACKING = 'packing',
  PACKED = 'packed',
  CANCELLED = 'cancelled',
}
