export enum StockTransferStatusEnum {
  TRANSFERRING = 'transferring',
  UPDATING = 'updating',
  UPDATE_FAILED = 'update-failed',
  TRANSFERRED = 'transferred',
  CANCELLED = 'cancelled',
}

export enum StockTransferSearchCriteriaEnum {
  ALL = 'all',
  TRANSFERRING = 'transferring',
  UPDATING = 'updating',
  UPDATE_FAILED = 'update-failed',
  TRANSFERRED = 'transferred',
  CANCELLED = 'cancelled',
}
