import { ReplenishTask } from 'commons/types';

export function compareReplenishTasks(
  a: ReplenishTask,
  b: ReplenishTask,
): number {
  if (a.type === 'picker_request' && b.type !== 'picker_request') return -1;
  if (a.type !== 'picker_request' && b.type === 'picker_request') return 1;
  if (a.is_high_priority && !b.is_high_priority) return -1;
  if (!a.is_high_priority && b.is_high_priority) return 1;
  return 0;
}
