import React from 'react';

import {
  Card,
  CardContent,
  Grid,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
} from '@mui/material';

import { SearchWithCriteria } from 'commons/components/SearchRelated';
import { ListFilterCardProps } from 'commons/types/listPageData.interface';

export const ListFilterCard: React.FC<
  React.PropsWithChildren<ListFilterCardProps>
> = (params) => {
  const {
    children,
    statusArrayText,
    statusFilter,
    handleStatusFilter,
    searchCriteria,
  }: React.PropsWithChildren<ListFilterCardProps> = params;
  return (
    <Card style={{ overflow: 'visible' }}>
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        {searchCriteria && (
          <>
            <SearchWithCriteria searchCriteriaOptions={searchCriteria} />
            <Divider variant="fullWidth" />
          </>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Typography
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                height: '100%',
              }}
              variant="inherit"
              component="p"
            >
              Status
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <ToggleButtonGroup
              value={statusFilter}
              onChange={handleStatusFilter}
              exclusive
              fullWidth
            >
              {statusArrayText.map((status) => (
                <ToggleButton value={status.key} key={status.key}>
                  {status.text}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>
        </Grid>
        {children}
      </CardContent>
    </Card>
  );
};
