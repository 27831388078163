export class CsvTemplateBuilder {
  private name: string;

  private header: string[] = [];

  private rows: string[][] = [];

  private readonly delimiters: string[] = [',', ';', '|', '\t', ' '];

  private delimiter: string = ',';

  constructor(name: string, delimiter?: string) {
    this.name = name;

    if (delimiter) {
      this.setDelimiter(delimiter);
    }
  }

  setDelimiter(delimiter: string): void {
    if (this.delimiters.includes(delimiter)) {
      this.delimiter = delimiter;
    }
  }

  setHeader(headerKeys: string[]): CsvTemplateBuilder {
    this.header = headerKeys;
    return this;
  }

  addRowValues(rowValues: string[]): CsvTemplateBuilder {
    this.rows.push(rowValues);
    return this;
  }

  buildCsv(): void {
    const link = this.createLink();
    document.body.appendChild(link);
    link.click();
  }

  createLink(): HTMLAnchorElement {
    const csvFormat = this.getCsvFormat;
    const encodedUri = encodeURI(csvFormat);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${this.name}.csv`);
    return link;
  }

  private get getCsvFormat(): string {
    return `data:text/csv;charset=utf-8,${this.getFormattedCsvContent()}`;
  }

  private getFormattedCsvContent = (): string => {
    if (this.header.length === 0) {
      const { log } = console;
      log(`Headers are not set for ${this.name}, will be using default`);
    }
    const completeData = [this.header, ...this.rows];
    return completeData.map((e) => e.join(this.delimiter)).join('\n');
  };
}
