/* eslint-disable react/no-unused-prop-types */
import React from 'react';

import { ClickAwayListener, MenuList, MenuItem } from '@mui/material';

import { PickingTask } from 'commons/types';

import { RowMenuItemConfig } from '../configs';

const BulkActionBtnMenu: React.FC<{
  selectedOrders: PickingTask[];
  handleCloseMenu: () => void;
  handleOpen: (name: string) => void;
}> = (params) => {
  const {
    selectedOrders,
    handleCloseMenu,
    handleOpen,
  }: {
    selectedOrders: PickingTask[];
    handleCloseMenu: () => void;
    handleOpen: (name: string) => void;
  } = params;

  return (
    <ClickAwayListener onClickAway={handleCloseMenu}>
      <MenuList id="split-button-menu" autoFocusItem>
        {RowMenuItemConfig.map((config) => (
          <MenuItem
            onClick={() => handleOpen(config.name)}
            disabled={config.disabled(selectedOrders)}
            key={`menu-item-${config.name}`}
          >
            {config.name}
          </MenuItem>
        ))}
      </MenuList>
    </ClickAwayListener>
  );
};

export default BulkActionBtnMenu;
