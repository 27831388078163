import { Grid } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';

class ImagePreviewStyles extends CommonComponentStyle {
  CameraHeader = styled(Grid)`
    padding: 16px;
    background-color: ${this.colors.PRIMARY_BLACK};
    color: ${this.colors.PRIMARY_WHITE};
    position: absolute;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  `;
}

export const ImagePreviewStyle = new ImagePreviewStyles();
