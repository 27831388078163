import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { CancelRounded, Close } from '@mui/icons-material';
import { Button, Drawer, Grid, IconButton } from '@mui/material';

import { ImagePreview } from 'commons/components/ImagePreview';
import * as colors from 'commons/styles/colors';
import { deleteBasketPhoto } from 'redux-stores/actions/packingTask.action';
import { AppDispatch } from 'redux-stores/store';

import { CheckBasketStyle as S } from '../CheckBasket.styles';

import BasketCamera from './BasketCamera.component';

interface IUploadPhotoModalProps {
  openModal: boolean;
  onClose: () => void;
  basketNumber: number;
  poNumber: string;
  photos: string[];
  isDisabled: boolean;
}

const UploadPhotoModal: React.FC<IUploadPhotoModalProps> = ({
  openModal,
  onClose,
  basketNumber,
  poNumber,
  photos,
  isDisabled,
}) => {
  const [isOpenCamera, setIsOpenCamera] = useState<boolean>(false);
  const [isOpenReview, setIsOpenReview] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const dispatch = useDispatch<AppDispatch>();

  const handleOpenCamera = () => {
    setIsOpenCamera(true);
  };

  const handleCloseCamera = () => {
    setIsOpenCamera(false);
  };

  const handleOpenReview = (imageSrc: string) => {
    setImageSrc(imageSrc);
    setIsOpenReview(true);
  };

  const handleCloseReview = () => {
    setIsOpenReview(false);
  };

  const handleDeletePhoto = async (
    e: React.MouseEvent<HTMLButtonElement>,
    photoLink: string,
  ) => {
    e.stopPropagation();
    dispatch(
      deleteBasketPhoto({
        poNumber,
        basket: basketNumber,
        photoLink,
      }),
    );
  };

  return (
    <>
      <Drawer anchor="bottom" open={openModal} onClose={onClose}>
        <S.DrawerHeader container>
          <Grid
            container
            xs={11}
            style={{ paddingLeft: '8px', alignItems: 'center' }}
          >
            <S.BoldText>{`Take Photos of Basket ${basketNumber}`}</S.BoldText>
          </Grid>
          <Grid container xs={1} alignItems="center" justifyContent="flex-end">
            <IconButton onClick={onClose}>
              <Close fontSize="small" />
            </IconButton>
          </Grid>
        </S.DrawerHeader>
        <Grid
          style={{
            padding: '8px',
            gap: '16px',
            alignItems: 'center',
            overflowX: 'scroll',
            flexWrap: 'nowrap',
          }}
          container
        >
          {photos.map((photo) => (
            <S.BasketPhotoWrapper onClick={() => handleOpenReview(photo)}>
              <S.BasketPhoto alt="basket check" src={photo} />
              <S.DeletePhoto
                onClick={(event) => handleDeletePhoto(event, photo)}
              >
                <CancelRounded style={{ color: colors.PRIMARY_RED }} />
              </S.DeletePhoto>
            </S.BasketPhotoWrapper>
          ))}
          <Button
            onClick={handleOpenCamera}
            style={{ padding: '0', minWidth: 'inherit' }}
            disabled={isDisabled}
          >
            <S.BasketPhoto src="/assets/upload-photo.svg" alt="Upload button" />
          </Button>
        </Grid>
      </Drawer>
      <BasketCamera
        poNumber={poNumber}
        basketNumber={basketNumber}
        openModal={isOpenCamera}
        onClose={handleCloseCamera}
      />
      <ImagePreview
        openModal={isOpenReview}
        onClose={handleCloseReview}
        imageSrc={imageSrc || ''}
      />
    </>
  );
};

export default UploadPhotoModal;
